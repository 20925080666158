@import "setting";

.dashboard {
  padding: 15px 20px;
}

.box_section {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-bottom: 20px;
}

.dashboard_sidebar {
  width: 20%;
}

.dashboard_panel {
  width: 80%;
}

.section_row {
  background-color: white;
  border-radius: 10px;
  box-shadow: $box_shadow;
  padding: 10px;
  // min-height: 85vh;
}

.section_row_chart {
  background-color: white;
  border-radius: 10px;
  box-shadow: $box_shadow;
  padding: 10px;
  height: 300px;
  margin-bottom: 20px;
}

.section_title {
  font-size: $font-size-large;
  font-weight: 600;
  color: $normal_txt_color;
}

.top_row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}

.bottom_row {
  display: flex;
  justify-content: space-between;
  flex: 1;
}
.edit_section {
  flex: 0.62;
}

.status_section {
  flex: 0.38;
  padding-left: 10px;
}

.status_container {
  background-color: whitesmoke;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 0 2px 0px #0000006e;
  border-radius: 10px;
  margin-bottom: 20px;
}

.step_header {
  margin: 0;
  padding: 0;
  font-size: $font-size-base;
  color: $normal_txt_color;
  font-weight: 600;
  line-height: 1.3;
  display: flex;
  align-items: center;
}

.step_header > span {
  margin: 0;
  padding: 0;
  font-size: $font-size-small;
  color: $sub_txt-color;
  font-weight: 400;
}

.step_header > .anticon-check-circle {
  font-size: 20px;
  margin-right: 10px;
}

.status_bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  color: white;
  font-weight: 600;
  font-size: $font-size-large;
  margin-bottom: 10px;
  cursor: pointer;
}

.status_bar_active {
  background-color: #27ae60;
}

.status_bar_pending {
  background-color: #ffc940;
  color: $title-color;
}

.status_bar_inactive {
  background-color: #bd0000;
}

.status_bar_default {
  background-color: #525252;
}

.edit_container {
  background-color: whitesmoke;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 0 2px 0px #0000006e;
  border-radius: 10px;
  margin-bottom: 20px;
}

.request_row {
  display: flex;
  justify-content: flex-end;
  margin-top: -30px;
  margin-bottom: 15px;
}

.remaining_time {
  font-size: 30px;
  font-weight: 600;
  color: $title-color;
  margin-bottom: 10px;
}

.remaining_time > span {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 600;
}

.setting_box {
  margin: 0;
  padding: 0;
}

.setting_box > li {
  background-color: white;
  border-radius: 10px;
  box-shadow: $box_shadow;
  padding: 10px;
  width: 237px;
  height: 230px;
  display: inline-flex;
  margin-right: 15px;
  margin-bottom: 12px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.setting_box_content > img {
  width: 100%;
  object-fit: cover;
}

.setting_box_title > h3 {
  margin-top: 10px;
  font-size: 16px;
  color: $title-color;
}

.switch_line{
  display: flex;
  align-items: center;
}

.switch_line > p{
  margin-left: 10px;
}