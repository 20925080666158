// Theme Colors
$primary-color: #c73b27;
$primary-color-focus: #a0210e;
$secondary-color: #fff;
$title-color: #323232;
$span-color: #656565;
$sub_txt-color: #656565;
$bg-color: whitesmoke;
$menu_txt_color: #525252;
$header_bg_color: whitesmoke;
$icon_color: #525252;
$box_shadow: 0 0 10px 0px #0000002d;
$normal_txt_color: #525252;
$white: white;
$whitesmoke: whitesmoke;

// Typography
$font-size-small: 12px;
$font-size-base: 14px;
$font-size-large: 16px;
$font-size-h1: 28px;
$font-size-h3: 20px;
$font-size-h4: 18px;
$font-size-h5: 16px;
$font-size-h6: 14px;
$font-size-title: 20px;
$font-size-subhead: 16px;
$font-size-body: 14px;
$font-size-menu: 14px;
$font-size-button: 14px;
$font-size-label: 14px;
$font-size-form: 14px;
$font-size-small-label: 12px;
$font-size-small-form: 12px;
$font-size-small-menu: 12px;
$font-size-small-button: 12px;
$font-size-small-body: 12px;
$font-size-small-subhead: 12px;
$font-size-small-title: 12px;
