.status-card {
  padding: 20px;
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 10px 0px #0000002d;
  color: #455560;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: color 0.5s ease 0s;
  /* margin-bottom: 30px; */
  width: 24%;
  cursor: pointer;
}

.status-card__icon {
  width: 30%;
  height: 100%;
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.status-card__info {
  flex-grow: 1;
  text-align: center;
  z-index: 1;
  text-transform: capitalize;
}

.status-card__info > p {
  font-size: 2.5rem;
  font-weight: 600;
}

.status-card::before {
  content: "";
  width: 100%;
  padding-top: 100%;
  border-radius: 50%;
  background-image: linear-gradient(to top right, #c73b27, #c73b27);
  position: absolute;
  left: -50%;
  top: 0;
  transform: scale(0);
  transition: transform 0.8s ease 0s;
}

.status-card:hover::before {
  transform: scale(3);
}

.status-card:hover {
  color: #fff;
}
